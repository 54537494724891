<template>
  <v-container fluid fill-height>
    <v-row 
      align="center"
      justify="center"
    >
      <v-col>
        <div class="text-center mb-3">
          <v-avatar size="150">
            <img
              alt="user"
              src="/images/Icon.png"
            >
          </v-avatar>
        </div>
        <v-card
          class="mx-auto mb-3"
          max-width="500"
        >
          <v-list>
            <v-subheader>Choose the language</v-subheader>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in locales"
                :key="i"
                @click.stop="changeLocale(item)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
  export default {
    data:()=>({
      locales:[
        // {
        //   name:'Bahasa Melayu',
        //   label:'BM',
        //   value:'ms',
        // },
        // {
        //   name:'中文',
        //   label:'中文',
        //   value:'zh',
        // },
        {
          name:'English',
          label:'EN',
          value:'en',
        },
      ],
    }),
    created() {
      const query = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      if(query.lang) {
        var index = this.$_.findIndex(this.locales, function(locale) { return locale.value == query.lang })
        if(index) {
          this.changeLocale(this.locales[index])
        }
      }
    },
    methods:{
      changeLocale(locale) {
        var href = this.removeURLParameter(window.location.href,'lang')
        var query = (href.split('?')[1]) ? `?${href.split('?')[1]}` : ''
        window.location.href = `${window.location.href.split('?')[0]}${locale.value}/home${query}`
      },
      removeURLParameter(url, parameter) {
        //refer to https://stackoverflow.com/a/26257722
        var urlparts= url.split('?');   
        if (urlparts.length>=2) {
          var prefix= encodeURIComponent(parameter)+'=';
          var pars= urlparts[1].split(/[&;]/g);
          for (var i= pars.length; i-- > 0;) {   
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
              pars.splice(i, 1);
            }
          }
          url= urlparts[0]+'?'+pars.join('&');
          return url;
        } else {
          return url;
        }
      },
    },
  }
</script>  